import EditableJsonTree from "./JsonEditor";
import React, { useEffect, useRef, useState } from "react";
import { linearize, getObjectDifference } from "./Update";
import useAxios from "../hooks/useAxios";
import { ChromePicker } from "react-color";

import {
  Modal,
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  InputNumber,
  Checkbox,
  DatePicker,
} from "antd";
import { DeleteFilled, PlusOutlined } from "@ant-design/icons";
import { PEButton } from "./permissionComponents/index";
import moment from "moment";

const _ = require("lodash");

const { Option } = Select;

const defaultConfig = {
  fieldname: "",
  isEdit: "0",
  isRequired: "0",
  isVisible: "1",
  defaultfilter: "",
  addapi: "0",
  datatype: null,
  tablesource: "",
  showLabel: "1",
  dropdownlist: "",
  Downloadfile: "",
  isSearch: "0",
  productlist: "",
  productid: "",
  massupdate: "0",
  isConfirm: "0",
  isEditDisable: "0",
  DisableOnFieldValue: "",
  isRedirect: "0",
  FileType: "",
  FileSize: "",
  FilePath: "",
  FileName: "",
};

export default function FieldMappingEditor({
  referenceJson,
  field_mapping,
  onChange,
  apidata,
  viewlist,
  dropdowndata,
  view_type,
  filteredservice_id
}) {
  const [data, setData] = useState({});
  const [blockFieldMappings, setBlockFieldMappings] = useState({});
  const [leafFieldMappings, setLeafFieldMappings] = useState([]);
  const [fieldlist, setFieldlist] = useState([]);

  useEffect(() => {
    field_mapping.forEach((obj, index) => {
      obj["globalMappingSequence"] = index + 1;
    });
    // filter block and jsonData types
    let _blockFieldMappings = field_mapping.filter((mapping) => {
      return (
        mapping["datatype"] === "block" ||
        mapping["datatype"] === "jsondata" ||
        mapping["datatype"] === "hyperlink"
      );
    });

    // removing field_mappings with block Configs
    const leaf_field_mapping = field_mapping.filter((mapping) => {
      // return true
      return !(
        mapping["datatype"] === "block" ||
        mapping["datatype"] === "jsondata" ||
        mapping["datatype"] === "hyperlink"
      );
    });
    setLeafFieldMappings(leaf_field_mapping);

    // create nested object with configs as values
    const nestedConfig = createNestedJson(leaf_field_mapping);

    // initialize leaf nodes as empty objects for reference Json
    setLeafNodes(referenceJson, "[]");

    // merge the existing configs with referenceJson
    const mergedObj = _.mergeWith(referenceJson, nestedConfig);

    // to set the data type of the configs to object, once nested fields are found.
    try {
      _blockFieldMappings.forEach((mapping) => {
        const path = getObjectPath(mapping["BucketField"]);
        let currentLevel = mergedObj;
        for (let i = 0; i < path.length; i++) {
          if (i == path.length - 1) {
            if (currentLevel[path[i]]) {
              continue;
            } else {
              currentLevel[path[i]] = {};
              break;
            }
          } else {
            currentLevel = currentLevel[path[i]];
          }
        }
      });

      _blockFieldMappings = _blockFieldMappings.reduce((obj, value) => {
        if (!obj[value["BucketField"]]) {
          obj[value["BucketField"]] = [];
        }
        obj[value["BucketField"]].push(value);
        return obj;
      }, {});

      setData(mergedObj);
      setBlockFieldMappings({ ..._blockFieldMappings });
      setFieldlist(field_mapping);
    } catch (e) {
      console.log(e);
    }
  }, [referenceJson, field_mapping]);

  // onChange event to return the linearized field mapping
  const createFieldMapping = (value) => {
    const linearConfig = linearize(value);
    const fieldMapping = Object.keys(linearConfig).reduce((obj, key) => {
      const configs = JSON.parse(linearConfig[key]);
      configs.forEach((config) => {
        obj.push({
          ...config,
          BucketField: key,
        });
      });

      return obj;
    }, []);
    return fieldMapping;
  };

  const onChangeWithSequence = (new_field_mapping) => {
    let attributeName = "globalMappingSequence";
    let timestampAttributeName = "mappingSequenceTimestamp";

    new_field_mapping.sort((a, b) => {
      if (a[attributeName] !== b[attributeName]) {
        return a[attributeName] - b[attributeName];
      } else {
        const timestampA = a[timestampAttributeName] || 0;
        const timestampB = b[timestampAttributeName] || 0;
        return timestampB - timestampA;
      }
    });

    new_field_mapping = new_field_mapping.reduce((acc, obj) => {
      const {
        [timestampAttributeName]: timestamp,
        [attributeName]: mappingSequence,
        ...rest
      } = obj;
      acc.push(rest);
      return acc;
    }, []);

    onChange(new_field_mapping);
  };

  const onChangeHandler = (value) => {
    const _fieldlist = createFieldMapping(value);
    setLeafFieldMappings(_fieldlist);
    const new_field_mapping = createNewFieldMapping(
      _fieldlist,
      blockFieldMappings
    );
    setFieldlist(new_field_mapping);
    onChangeWithSequence(new_field_mapping);
  };

  // handler to save the config of block types
  const saveBlockConfig = (path, values) => {
    const bucketFieldString = getBucketFieldString(path);
    blockFieldMappings[bucketFieldString] = values;
    const new_field_mapping = createNewFieldMapping(
      leafFieldMappings,
      blockFieldMappings
    );
    setFieldlist(new_field_mapping);
    onChangeWithSequence(new_field_mapping);
  };

  const createNewFieldMapping = (fieldMappings, blockFieldMappings) => {
    // console.log(fieldMappings,blockFieldMappings)
    const new_field_mappings = [...fieldMappings];
    Object.keys(blockFieldMappings).forEach((bucketField) => {
      new_field_mappings.push(...blockFieldMappings[bucketField]);
    });
    return new_field_mappings;
  };

  // Handler to show only assigned fields and unassigned fields and all fields.
  const handleFilterSelect = (values) => { };

  // ValueRenderer for field mapping
  const filterOptions = [
    { option: "All fields", value: 0 },
    { option: "Mapped fields", value: 1 },
  ];

  return (
    <div className="view_field_mapping_editor">
      {/* <div className="d-flex justify-content-between pl-2">
            <div></div>
            <Select style={{width:'150px'}} onChange={handleFilterSelect} defaultValue={0}>
                {
                    filterOptions.map(({option,value}) => (
                        <Option key={option} value={value}>
                            {option}
                        </Option>
                    ))
                }
            </Select>
            
        </div> */}
      <EditableJsonTree
        editEnabled={false}
        data={data}
        onChange={onChangeHandler}
        ValueRenderer={({ path, jsonConfig, onFormSubmit, valueType }) => {
          return (
            <ConfigFormRenderer
              path={path}
              jsonConfig={jsonConfig}
              onFormSubmit={onFormSubmit}
              apidata={apidata}
              fieldlist={fieldlist}
              valueType={valueType}
              dropdownlist={dropdowndata}
              viewlist={viewlist}
              view_type={view_type}
              filteredservice_id={filteredservice_id}
            />
          );
        }}
        NestedValueRenderer={({
          path,
          valueType,
          value,
          itemString,
          itemType,
        }) => {
          const bucketFieldString = getBucketFieldString(path);

          const jsonConfig = JSON.stringify(
            blockFieldMappings[bucketFieldString]
              ? blockFieldMappings[bucketFieldString]
              : []
          );

          return (
            <ConfigFormRenderer
              path={path}
              jsonConfig={jsonConfig}
              onFormSubmit={saveBlockConfig}
              apidata={apidata}
              fieldlist={fieldlist}
              valueType={valueType}
              dropdownlist={dropdowndata}
              viewlist={viewlist}
              itemType={itemType}
              view_type={view_type}
              filteredservice_id={filteredservice_id}
            />
          );
        }}
      />
    </div>
  );
}

const ConfigFormRenderer = ({
  path,
  jsonConfig,
  onFormSubmit,
  apidata,
  valueType,
  fieldlist,
  dropdownlist,
  viewlist,
  itemType,
  view_type,
  filteredservice_id
}) => {
  const [loading, sendRequest] = useAxios();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [configs, setConfigs] = useState(JSON.parse(jsonConfig));
  const [dropdownResponse, setdropdownResponse] = useState();
  const [dropdownResponsebasedata, setdropdownResponsebasedata] = useState();
  const [allRules, setallRules] = useState([]);

  useEffect(() => {
    const _configs = jsonConfig ? JSON.parse(jsonConfig) : [];
    _configs.forEach((config, index) => {
      config["index"] = index;
    });
    setConfigs(_configs);
  }, [jsonConfig]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setConfigs(JSON.parse(jsonConfig));
    setIsModalVisible(false);
  };

  // Transformation Function to reformat the config object into 'datecondition' structure
  const transformConfigToDateCondition = (config) => {
    const dateCondition = Object.keys(config)
      .filter((key) => key.startsWith("operator-") || key.startsWith("day-") || key.startsWith("color-"))
      .reduce((acc, key) => {
        const [type, id] = key.split("-");
        if (!acc[id]) acc[id] = {};
        acc[id][type] = config[key];
        return acc;
      }, {});

    return Object.values(dateCondition);
  };

  const handleSave = (e) => {
    e.stopPropagation();

    // Apply the transformation to the configs before submitting
    const transformedConfigs = configs.map((config) => {
      const transformedDateCondition = transformConfigToDateCondition(config);
      return {
        ...config,
        datecondition: transformedDateCondition, // Add transformed datecondition to config
      };
    });

    onFormSubmit(
      path,
      transformedConfigs.reduce((obj, config) => {
        const { index, ...rest } = config;
        obj.push({ BucketField: getBucketFieldString(path), ...rest });
        return obj;
      }, [])
    );

    setIsModalVisible(false); // Close the modal after saving
  };

  // const handleSave = (e) => {
  //   e.stopPropagation();

  //   onFormSubmit(
  //     path,
  //     configs.reduce((obj, config) => {
  //       const {index:index,...rest} = config;
  //       obj.push({ BucketField: getBucketFieldString(path), ...rest});
  //       return obj;
  //     }, [])
  //   );
  //   setIsModalVisible(false);
  // };

  const fieldNames = configs.reduce((obj, config) => {
    obj.push(config["fieldname"]);
    return obj;
  }, []);

  // getBucketFieldString

  const updateConfig = (config, index) => {
    const _configs = [...configs];
    config["index"] = index;
    _configs.splice(index, 1, config);
    setConfigs(_configs);
  };

  const deleteConfig = (index) => {
    const _configs = [...configs];
    _configs.splice(index, 1);
    setConfigs(_configs);
  };

  const handleAddField = () => {
    const _configs = [...configs];
    defaultConfig["index"] = _configs.length;
    _configs.push(defaultConfig);
    setConfigs(_configs);
  };
  useEffect(() => {
    const uniqueItems = dropdownResponsebasedata && dropdownResponsebasedata.filter((item, index, self) => {
      // Check if this item_value is unique in the array
      return self.findIndex(i => i.item_value === item.item_value) === index;
    });
    setdropdownResponse(uniqueItems);
  }, [dropdownResponsebasedata]);
  const filteroutput = (dropdownlistid) => {
    let apitoken = localStorage.getItem("api_token");

    // Ensure dropdownlistid is processed as an array
    const dropdownIds = Array.isArray(dropdownlistid) ? dropdownlistid : [dropdownlistid];

    dropdownIds.forEach((id) => {
      sendRequest(
        {
          url: `${process.env.REACT_APP_BASE_URL}/get/dropdown/item/list?dropdown_id=${id}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "api-token": apitoken,
          },
        },
        (response) => {
          console.log("dropdownlist", response);
          setdropdownResponsebasedata((prev) => [...(prev || []), ...response]); // Handle non-filtered data          
        }
      );
    });

    if (fieldlist[0].search_query) {
      const allRules = extractAllRules(fieldlist[0].search_query.rules);
      const groupedData = Object.values(groupData(allRules));
      console.log("ConfigFormRenderer", groupedData);
      setallRules(groupedData);
    }
  };

  const groupData = (data) => {
    return data.reduce((acc, item) => {
      const field = item.field;
      if (!acc[field]) {
        acc[field] = { field, values: [] };
      }
      acc[field].values.push(item.value);
      return acc;
    }, {});
  };
  return (
    <>
      <div
        style={{ display: "inline" }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <span onClick={showModal} style={{ cursor: "pointer" }}>
          [{" "}
          {configs.map((config, index) => {
            return (
              <span key={config["fieldname"]}>
                {config["fieldname"]}
                <span style={{ color: "grey" }}>
                  {" "}
                  ({config["globalMappingSequence"]})
                </span>
                {configs.length - 1 != index ? ", " : ""}
              </span>
            );
          })}{" "}
          ]
        </span>
        <Modal
          title="Fields"
          open={isModalVisible}
          onCancel={handleCancel}
          width={"100%"}
          height={"80%"}
          footer={null}
        >
          {configs.map((c,index) => {
            return (
              <div className="view_field_form_container" key={c.fieldname}>
                <FieldMappingForm
                  config={c}
                  fieldlist={fieldlist}
                  apidata={apidata}
                  dropdownlist={dropdownlist}
                  viewlist={viewlist}
                  setConfig={(newConfig) => {
                    updateConfig(newConfig, index);
                  }}
                  deleteConfig={() => {
                    deleteConfig(index);
                  }}
                  filteroutput={(e) => {
                    filteroutput(e);
                  }}
                  valueType={valueType}
                  path={path}
                  dropdownResponse={dropdownResponse}
                  allRules={allRules}
                  view_type={view_type}
                />
              </div>
            );
          })}
          <Row justify="center" align="middle" gutter={8}>
            <Col>
              <PEButton element_id="createview_newfield"
                className="add_view_field_button"
                onClick={handleAddField}
                type="primary"
              >
                New Field <PlusOutlined />
              </PEButton>
            </Col>
            <Col>
              <PEButton element_id="createview_save"
                className="add_view_field_button"
                onClick={handleSave}
                type="primary"
              >
                Save
              </PEButton>
            </Col>
          </Row>
        </Modal>
      </div>
    </>
  );
};
const extractAllRules = (rules) => {
  let result = [];

  rules.forEach((rule) => {
    if (rule.rules) {
      // Recursively handle nested rules
      result = result.concat(extractAllRules(rule.rules));
    } else {
      result.push({
        field: rule.field,
        operator: rule.operator,
        value: rule.value,
      });
    }
  });

  return result;
};
const FieldMappingForm = ({
  config,
  setConfig,
  deleteConfig,
  filteroutput,
  fieldlist,
  apidata,
  dropdownlist,
  viewlist,
  valueType,
  path,
  dropdownResponse,
  allRules,
  view_type,
}) => {
  const [form] = Form.useForm();
  config = { ...defaultConfig, ...config };
  const [editDisableConfig, setEditDisableConfig] = useState(
    config.isEditDisable
  );
  const [loading, sendRequest] = useAxios();
  const [addApiConfig, setAddApiConfig] = useState(config.addapi);
  const [datatypeConfig, setDatatypeConfig] = useState(config.datatype || "");
  const [redirectConfig, setRedirectConfig] = useState(config.isRedirect);
  const [colorPickers, setColorPickers] = useState({}); // Initialize colorPickers state
  const [fieldSets, setFieldSets] = useState([{ id: 0 }]);
  
  const [document_group, setdocument_group] = useState([]);
  const [document_type, setdocument_type] = useState([]);
  const [fileupload, setfileupload] = useState([
    {
      selectType: "",
      UploadType: "",
      UploadSize: "",
      UploadPath: "",
      UploadName: "",
      UploadRequired: "0",
      UploadIsMultiple: "0",
      UploadRetention: "",
    },
  ]);
  useEffect(() => {
    let apitoken = localStorage.getItem("api_token");
    sendRequest(
      {
        url: `${process.env.REACT_APP_BASE_URL}/fetch/all/upload/document/type`,
        method: "GET",
        headers: {
          "api-token": apitoken,
        },
      },
      (response) => {
        console.log("document_group", response.response);
        setdocument_group(response.response);
      }
    );
    if (!config.fileupload) {
      console.log("config.fileupload not found");

      //setConfig((prevConfig) => ({ ...prevConfig, fileupload: [] }));
      setConfig({ ...config, fileupload: [] });
    }
  }, []);
  // useEffect(() => {
  //   // Update config's fileupload whenever fileupload changes
  //   setConfig((prevConfig) => ({ ...prevConfig, fileupload }));
  // }, [fileupload]);
  const getdoctype = (e) => {
    let apitoken = localStorage.getItem("api_token");
    sendRequest(
      {
        url: `${process.env.REACT_APP_BASE_URL}/fetch/upload/document/type/by/id?upload_document_type_group_id=${e}`,
        method: "GET",
        headers: {
          "api-token": apitoken,
        },
      },
      (response) => {
        console.log("document_type", response.response);
        setdocument_type(response.response);
      }
    );
  };

  const handleAdd = () => {
    console.log("handleAdd_config--", config);
    const newItem = {
      selectType: "",
      UploadType: "",
      UploadSize: "",
      UploadPath: "",
      UploadName: "",
      UploadRequired: "0",
      UploadIsMultiple: "0",
      UploadRetention: "",
    };
    // setConfig((prevConfig) => ({
    //   ...prevConfig,
    //   fileupload: [...(prevConfig.fileupload || []), newItem],
    // }));
    setConfig({
      ...config,
      fileupload: [...(config.fileupload || []), newItem], // Append newItem to fileupload
    });
  };

  const handleRemove = (index) => {
    const newfileupload = [...(config.fileupload || [])];
    newfileupload.splice(index, 1);

    // setConfig((prevConfig) => ({
    //   ...prevConfig,
    //   fileupload: newfileupload,
    // }));
    setConfig({ ...config, fileupload: newfileupload });
  };

  const handleInputChange = (value, index, field) => {
    const newfileupload = JSON.parse(JSON.stringify(config.fileupload || []));
    newfileupload[index][field] = value;

    // setConfig((prevConfig) => ({
    //   ...prevConfig,
    //   fileupload: newfileupload,
    // }));
    setConfig({ ...config, fileupload: newfileupload });
  };
  const [colorbtn, setcolorbtn] = useState(false);
  const [showConfigDropdown, setShowConfigDropdown] = useState(false);
  const [showDateTimeFormatDropdown, setShowDateTimeFormatDropdown] = useState(false);
  const [showTextField, setShowTextField] = useState(false);

  const yesnoOptions = [
    { option: "Yes", value: "1" },
    { option: "No", value: "0" },
  ];
  const configOptions = [
    { option: "Transaction No", value: "transaction_no" },
    // { option: "Reference No", value: "reference_no" },
    { option: "User ID", value: "user_id" },
    { option: "Date & Time", value: "date_time" },
    { option: "Random", value: "random" },
    
  ];
  const dateTimeFormats = [
    { option: "ISO", value: "iso" },
    { option: "US", value: "us" },
    { option: "European/International", value: "intl" },
    { option: "Unix Epoch", value: "unix" },
    { option: "RFC 2822", value: "rfc2822" },
    { option: "Custom", value: "custom" },
  ];
  const dropdownfilter = [
    { option: "Mass Update", value: "MU" },
    { option: "Single Row", value: "RD" },
    { option: "Both", value: "BD" },
  ];
  const iconPosition = [
    { option: "Prefix", value: "PF" },
    { option: "Suffix", value: "SF" },
  ];
  const iconList = [
    { option: "Cash", value: "BsCashStack" },
    { option: "Rupee", value: "BsCurrencyRupee" },
  ];
  const showonOptions = [
    { option: "Screen", value: "1" },
    { option: "Popup", value: "0" },
  ];
  const aggregateOptions = [
    { option: "Aggregate Data", value: "AD" },
    { option: "Group By", value: "GD" },
    { option: "First Row", value: "FR" },
    { option: "Hide Data", value: "HD" },
  ];

  const dropdown_Condition = [
    { option: "Check All Checkbox", value: "1" },
    { option: "Partial Submit", value: "2" },
  ];
  const dropDownOptions = [
    { option: "Show Value", value: "V" },
    { option: "Show Label", value: "L" },
    { option: "Show Both", value: "B" },
  ];

  const hyperlinkdata = [
    // { option: "Formview", value: "FV" },
    { option: "API", value: "API" },
    { option: "Payload Data", value: "PD" },
    { option: "Product List", value: "productlist" },
  ];
  const defaultfilterOptions = [
    { option: "contextuser_id", value: "==.contextuser_id" },
    {
      option: "contextuser_branchid",
      value: "array_contains_any.contextuser_branchid",
    },
  ];
  const datatypeOptions = [
    { option: "Textbox", value: "textbox" },
    { option: "Date", value: "date" },
    { option: "Boolean", value: "boolean" },
    { option: "Image", value: "image" },
    { option: "Dropdown List", value: "dropdownlist" },
    { option: "INT", value: "int" },
    { option: "Float", value: "float" },
    { option: "Sub View", value: "sub_view" },
    { option: "Group ID", value: "group_id" },
    // { option: "Get Product List", value: "productlist" },
    // { option: "Uploadfile", value: "Uploadfile" },
    { option: "Select", value: "selectoption" },
    { option: "Download / Upload", value: "Downloadfile" },
    { option: "Hyperlink", value: "hyperlink" },
    { option: "Block", value: "block" },
    { option: "JSON", value: "jsondata" },
  ];

  useEffect(() => {
    if (config) {
      if (config.autogeneratetxn === "1") {
        setShowConfigDropdown(true);
        if (config.selectConfig === "date_time") {
          setShowDateTimeFormatDropdown(true);
        }
        if (
          config.selectConfig === "transaction_no" ||
          config.selectConfig === "reference_no" ||
          config.selectConfig === "random"
        ) {
          setShowTextField(true);
        }
      }
    }
  }, [config]);

  let field_path = getBucketFieldString(path);
  let isArray = 0;
  if (field_path.indexOf(":") !== -1) {
    isArray = 1;
  } else {
    isArray = 0;
  }

  if (valueType == "string") {
    datatypeOptions.splice(datatypeOptions.length - 2, 2);
  } else {
    datatypeOptions.splice(0, datatypeOptions.length - 4);
  }

  const valueChangeHandler = (changedValues, allValues) => {
    if (changedValues["globalMappingSequence"]) {
      allValues["mappingSequenceTimestamp"] = Date.now();
    }

    console.log("allValues", allValues);
    setConfig({ ...config, ...allValues });
  };
  const handleCheckboxChange = (e, itemId) => {
    const value = e.target.value;
    const dropdowncondition = config.dropdowncondition || [];
    const checked = e.target.checked;

    // Check if the item is already in the dropdowncondition
    const existingSelection = dropdowncondition.find(
      (item) => item.filtereddropdownlist === itemId
    );

    if (checked) {
      if (existingSelection) {
        // Update the existing item
        const updatedSelections = {
          ...config,
          dropdowncondition: dropdowncondition.map((item) =>
            item.filtereddropdownlist === itemId
              ? { ...item, filtereddropdownlist: value }
              : item
          ),
        };
        setConfig(updatedSelections);
      } else {
        // Add new item
        const updatedSelections = {
          ...config,
          dropdowncondition: [
            ...dropdowncondition,
            { filtereddropdownlist: value, filteredCondition: null },
          ],
        };
        console.log("ConditionChange_", updatedSelections);

        setConfig(updatedSelections);
      }
    } else {
      const updatedSelections = {
        ...config,
        dropdowncondition: dropdowncondition.filter(
          (item) => item.filtereddropdownlist !== value
        ),
      };
      console.log("ConditionChange_1", updatedSelections);
      setConfig(updatedSelections);
    }
  };

  const handleConditionChange = (value, itemId) => {
    const dropdowncondition = config.dropdowncondition || [];

    const updatedSelections = {
      ...config,
      dropdowncondition: dropdowncondition.map((item) =>
        item.filtereddropdownlist === itemId
          ? { ...item, filteredCondition: value }
          : { ...item }
      ),
    };
    console.log("ConditionChange", value);

    setConfig(updatedSelections);
  };
  const handleMassValidationKey = (value, itemId) => {
    const dropdowncondition = config.dropdowncondition || [];

    const updatedSelections = {
      ...config,
      dropdowncondition: dropdowncondition.map((item) =>
        item.filtereddropdownlist === itemId
          ? { ...item, massValidationKey: value }
          : { ...item }
      ),
    };
    console.log("handleMassValidationKey", value);

    setConfig(updatedSelections);
  };
  const handleusedfor = (value, itemId) => {
    const dropdowncondition = config.dropdowncondition || [];

    const updatedSelections = {
      ...config,
      dropdowncondition: dropdowncondition.map((item) =>
        item.filtereddropdownlist === itemId
          ? { ...item, usedfor: value }
          : { ...item }
      ),
    };
    console.log("handleusedfor", value);

    setConfig(updatedSelections);
  };
  const handleMassValidationvalue = (value, itemId) => {
    const dropdowncondition = config.dropdowncondition || [];

    const updatedSelections = {
      ...config,
      dropdowncondition: dropdowncondition.map((item) =>
        item.filtereddropdownlist === itemId
          ? { ...item, massValidationvalue: value }
          : { massValidationvalue: value }
      ),
    };
    console.log("handleMassValidationKey", value);

    setConfig(updatedSelections);
  };

  const handleInputcoloeChange = (field, value) => {
    console.log("field", field);
    console.log("value", value);

    const updatedSelections = {
      ...config,
      [field]: value,
    };
    console.log("handleInputChange_", updatedSelections);

    setConfig(updatedSelections);
  };

  // Function to toggle color picker display
  const toggleColorPicker = (index) => {
    const newColorPickers = { ...colorPickers };
    newColorPickers[index] = {
      display: newColorPickers[index] && newColorPickers[index].display
        ? !newColorPickers[index].display
        : true,
      color: newColorPickers[index] && newColorPickers[index].color
        ? newColorPickers[index].color
        : config[`color-${index}`] || "#ffffff",
    };
    setColorPickers(newColorPickers);
  };

  const handleColorChange = (color, index) => {
    const newColorPickers = { ...colorPickers };
    newColorPickers[index] = { ...newColorPickers[index], color: color.hex };
    setColorPickers(newColorPickers);

    valueChangeHandler(
      { [`color-${index}`]: color.hex },
      { ...config, [`color-${index}`]: color.hex }
    );
  };
  // Function to add a new field set
  const addFieldSet = () => {
    setFieldSets([...fieldSets, { id: fieldSets.length }]);
  };
  // Function to remove a field set
  const removeFieldSet = (id) => {
    setFieldSets(fieldSets.filter((fieldSet) => fieldSet.id !== id));
  };

  return (
    <Form
      className="view_field_form"
      layout="vertical"
      form={form}
      onValuesChange={valueChangeHandler}
      initialValues={config}
    >
      <Row gutter={[24, 16]}>
        <Col span={6}>
          <Form.Item
            label="Field Name"
            name="fieldname"
            initialValue={config.fieldname}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item
            label="Can Edit"
            name="isEdit"
            initialValue={config.isEdit || ""}
          >
            <Select>
              <Option value="" disabled>
                Select
              </Option>
              {yesnoOptions.map(({ option, value }) => (
                <Option key={option} value={value}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item
            label="is Required"
            name="isRequired"
            initialValue={config.isRequired || ""}
          >
            <Select>
              <Option value="" disabled>
                Select
              </Option>
              {yesnoOptions.map(({ option, value }) => (
                <Option key={option} value={value}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item
            label="is Visible"
            name="isVisible"
            initialValue={config.isVisible || ""}
          >
            <Select>
              <Option value="" disabled>
                Select
              </Option>
              {yesnoOptions.map(({ option, value }) => (
                <Option key={option} value={value}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item
            label="is Search"
            name="isSearch"
            initialValue={config.isSearch || ""}
          >
            <Select>
              <Option value="" disabled>
                Select
              </Option>
              {yesnoOptions.map(({ option, value }) => (
                <Option key={option} value={value}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item
            label="show Label?"
            name="showLabel"
            initialValue={config.showLabel || ""}
          >
            <Select>
              <Option value="" disabled>
                Select
              </Option>
              {yesnoOptions.map(({ option, value }) => (
                <Option key={option} value={value}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item
            label="Default Filter"
            name="defaultfilter"
            initialValue={config.defaultfilter || ""}
          >
            <Select>
              <Option value="">
                Select
              </Option>
              {defaultfilterOptions.map(({ option, value }) => (
                <Option key={option} value={value}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item
            label="Mass Update"
            name="massupdate"
            initialValue={config.massupdate || ""}
          >
            <Select>
              <Option value="" disabled>
                Select
              </Option>
              {yesnoOptions.map(({ option, value }) => (
                <Option key={option} value={value}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {config.massupdate == 1 && (
          <>
            <Col span={3}>
              <Form.Item
                label="Show On"
                name="showon"
                initialValue={config.showon || ""}
              >
                <Select>
                  <Option value="" disabled>
                    Select
                  </Option>
                  {showonOptions.map(({ option, value }) => (
                    <Option key={option} value={value}>
                      {option}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item
                label="Show Condition"
                name="showcondition"
                initialValue={config.showcondition || ""}
              >
                <Select>
                  <Option value="" disabled>
                    Select
                  </Option>
                  {yesnoOptions.map(({ option, value }) => (
                    <Option key={option} value={value}>
                      {option}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item
                label="Is Required?"
                name="massrequire"
                initialValue={config.massrequire || ""}
              >
                <Select>
                  <Option value="" disabled>
                    Select
                  </Option>
                  {yesnoOptions.map(({ option, value }) => (
                    <Option key={option} value={value}>
                      {option}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {config.showcondition == 1 && (
              <>
                <Col span={3}>
                  <Form.Item
                    label="Select Field"
                    name="mass_condition_field"
                    initialValue={config.mass_condition_field || ""}
                  >
                    <Select showSearch>
                      <Option value="" disabled>
                        Select
                      </Option>
                      {fieldlist.map(({ BucketField, fieldname }) => (
                        <Option key={fieldname} value={BucketField}>
                          {fieldname}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item
                    label="Add Condition"
                    name="mass_condition_value"
                    initialValue={config.mass_condition_value}
                    placeholder="10,20,30"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </>
            )}
            <Col span={3}>
              <Form.Item
                label="Use As Input"
                name="useasinput"
                initialValue={config.useasinput || ""}
              >
                <Select>
                  <Option value="" disabled>
                    Select
                  </Option>
                  {yesnoOptions.map(({ option, value }) => (
                    <Option key={option} value={value}>
                      {option}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </>
        )}
        <Col span={3}>
          <Form.Item
            label="Add API?"
            name="addapi"
            initialValue={config.addapi || ""}
          >
            <Select onChange={(value) => setAddApiConfig(value)}>
              <Option value="" disabled>
                Select
              </Option>
              {yesnoOptions.map(({ option, value }) => (
                <Option key={option} value={value}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {addApiConfig == "1" && (
          <Col span={4}>
            <Form.Item
              label="Select API"
              name="selectapi"
              initialValue={config.selectapi}
            >
              <Select showSearch>
                <Option value="" disabled>
                  Select
                </Option>
                {apidata.map(({ action_name }) => (
                  <Option key={action_name} value={action_name}>
                    {action_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}
        {/* Auto Generate Config Dropdown */}
        <Col span={3}>
          <Form.Item
            label="Auto Generate Config"
            name="autogeneratetxn"
          >
            <Select
              onChange={(value) => {
                setShowConfigDropdown(value === "1");
                if (value !== "1") {
                  setShowDateTimeFormatDropdown(false);
                  setShowTextField(false);
                  form.setFieldsValue({
                    selectConfig: undefined,
                    dateTimeFormat: undefined,
                    regularExpression: undefined,
                  });
                }
              }}
            >
              <Option value="" disabled>
                Select
              </Option>
              {yesnoOptions.map(({ option, value }) => (
                <Option key={value} value={value}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        {/* Second Dropdown: Select Config */}
        {showConfigDropdown && (
          <Col span={3}>
            <Form.Item
              label="Select Config"
              name="selectConfig"
            >
              <Select
                onChange={(value) => {
                  setShowDateTimeFormatDropdown(value === "date_time");
                  setShowTextField(
                    value === "transaction_no" || value === "random"
                  );
                  form.setFieldsValue({
                    dateTimeFormat: undefined,
                    regularExpression: undefined,
                  });
                }}
              >
                <Option value="" disabled>
                  Select
                </Option>
                {configOptions.map(({ option, value }) => (
                  <Option key={value} value={value}>
                    {option}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}

        {/* Third Dropdown: Date-Time Format */}
        {showDateTimeFormatDropdown && (
          <Col span={3}>
            <Form.Item
              label="Date-Time Format"
              name="dateTimeFormat"
            >
              <Select>
                <Option value="" disabled>
                  Select Format
                </Option>
                {dateTimeFormats.map(({ option, value }) => (
                  <Option key={value} value={value}>
                    {option}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}

        {/* Text Field: Enter Regular Expression */}
        {showTextField && (
          <Col span={3}>
            <Form.Item
              label="Enter Regular Expression"
              name="regularExpression"
            >
              <Input placeholder="Enter Regular Expression" />
            </Form.Item>
          </Col>
        )}     
        <Col span={3}>
          <Form.Item
            label="Add to SUM"
            name="addtosum"
            initialValue={config.addtosum || ""}
          >
            <Select>
              <Option value="" disabled>
                Select
              </Option>
              {yesnoOptions.map(({ option, value }) => (
                <Option key={option} value={value}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {config.addtosum == "1" && (
          <>
            <Col span={3}>
              <Form.Item
                label="Select Field"
                name="sumFieldlist"
                initialValue={config.sumFieldlist || ""}
              >
                <Select>
                  <Option value="" disabled>
                    Select
                  </Option>
                  {fieldlist.map(({ BucketField, fieldname }) => (
                    <Option key={fieldname} value={BucketField}>
                      {fieldname}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item
                label="Can Submit Partial Amount"
                name="partial_amount"
                initialValue={config.partial_amount || ""}
              >
                <Select>
                  <Option value="" disabled>
                    Select
                  </Option>
                  {yesnoOptions.map(({ option, value }) => (
                    <Option key={option} value={value}>
                      {option}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {config.partial_amount == 1 && (
              <Col span={3}>
                <Form.Item
                  label="Can Submit More Than Collection"
                  name="morethan_collection"
                  initialValue={config.morethan_collection || ""}
                >
                  <Select>
                    <Option value="" disabled>
                      Select
                    </Option>
                    {yesnoOptions.map(({ option, value }) => (
                      <Option key={option} value={value}>
                        {option}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
          </>
        )}
        <Col span={4}>
          <Form.Item
            label="Require Confirmation?"
            name="isConfirm"
            initialValue={config.isConfirm || ""}
          >
            <Select>
              <Option value="" disabled>
                Select
              </Option>
              {yesnoOptions.map(({ option, value }) => (
                <Option key={option} value={value}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Disable Edit"
            name="isEditDisable"
            initialValue={config.isEditDisable || ""}
          >
            <Select onChange={(value) => setEditDisableConfig(value)}>
              <Option value="" disabled>
                Select
              </Option>
              {yesnoOptions.map(({ option, value }) => (
                <Option key={option} value={value}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {editDisableConfig == "1" && (
          <Col span={6}>
            <Form.Item
              label="Disable Value(bucket field operator value)"
              name="DisableOnFieldValue"
              initialValue={config.DisableOnFieldValue}
              placeholder="payload.status>40"
            >
              <Input />
            </Form.Item>
          </Col>
        )}
      </Row>
      <Row gutter={[24, 16]}>
        {/* Data Type Dropdown */}
        <Col span={5}>
          <Form.Item
            label="Data Type"
            name="datatype"
            initialValue={config.datatype || ""}
          >
            <Select
              onChange={(value) => {
                setDatatypeConfig(value); // Update state for datatypeConfig
                valueChangeHandler({ datatype: value }, { ...config, datatype: value }); // Update parent state
              }}
            >
              <Option value="" disabled>
                Select
              </Option>
              {datatypeOptions.map(({ option, value }) => (
                <Option key={option} value={value}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {datatypeConfig == "selectoption" && (
          <>
            <Col span={4}>
              <Form.Item
                label="Select API"
                name="selectoption"
                initialValue={config.selectoption}
              >
                <Select showSearch>
                  <Option value="" disabled>
                    Select
                  </Option>
                  {apidata.map(({ action_name }) => (
                    <Option key={action_name} value={action_name}>
                      {action_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </>
        )}
        {datatypeConfig == "Downloadfile" && (
          <>
            <Col span={5}>
              <Form.Item
                label="Is Upload"
                name="isupload"
                initialValue={config.isupload || ""}
              >
                <Select>
                  <Option value="" disabled>
                    Select
                  </Option>
                  {yesnoOptions.map(({ option, value }) => (
                    <Option key={option} value={value}>
                      {option}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {config.isupload == "1" && (
              <Col span={6}>
                <Form.Item
                  label="Household Id"
                  name="payloadpathIsupload"
                  initialValue={config.payloadpathIsupload}
                >
                  <Input />
                </Form.Item>
              </Col>
            )}
            <Col span={5}>
              <Form.Item
                label="Document Group"
                name="doc_group"
                initialValue={config.doc_group || ""}
              >
                <Select onChange={(e) => getdoctype(e)}>
                  <Option value="" disabled>
                    Select
                  </Option>
                  {document_group &&
                    document_group.map(
                      ({
                        upload_document_type_group_name,
                        upload_document_type_group_id,
                      }) => (
                        <Option
                          key={upload_document_type_group_name}
                          value={upload_document_type_group_id}
                        >
                          {upload_document_type_group_name}
                        </Option>
                      )
                    )}
                </Select>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                label="Download single file directly ?"
                name="singlefile_direct"
                initialValue={config.singlefile_direct || ""}
              >
                <Select>
                  <Option value="" disabled>
                    Select
                  </Option>
                  {yesnoOptions.map(({ option, value }) => (
                    <Option key={option} value={value}>
                      {option}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </>
        )}
        {config.isupload == "1" && (
          <>
            {(config.fileupload || []).map((item, index) => (
              <Row key={index} gutter={16} style={{ marginBottom: "16px" }}>
                {/* Dropdown for Select Type */}
                <Col span={4}>
                  <Form.Item label="Select Type">
                    <Select
                      value={item.selectType}
                      onChange={(value) =>
                        handleInputChange(value, index, "selectType")
                      }
                      placeholder="Select Type"
                    >
                      <Option value="" disabled>
                        Select
                      </Option>
                      {document_type.map(
                        ({
                          upload_document_type_name,
                          upload_document_type_id,
                        }) => (
                          <Option
                            key={upload_document_type_name}
                            value={upload_document_type_id}
                          >
                            {upload_document_type_name}
                          </Option>
                        )
                      )}
                    </Select>
                  </Form.Item>
                </Col>

                {/* Textbox for File Type */}
                <Col span={4}>
                  <Form.Item label="File Type">
                    <Input
                      value={item.UploadType}
                      onChange={(e) =>
                        handleInputChange(e.target.value, index, "UploadType")
                      }
                      placeholder="File Type"
                    />
                  </Form.Item>
                </Col>

                {/* Textbox for File Size */}
                <Col span={4}>
                  <Form.Item label="File Size">
                    <Input
                      value={item.UploadSize}
                      onChange={(e) =>
                        handleInputChange(e.target.value, index, "UploadSize")
                      }
                      placeholder="File Size"
                    />
                  </Form.Item>
                </Col>

                {/* Textbox for File Path */}
                <Col span={6}>
                  <Form.Item label="File Path">
                    <Input
                      value={item.UploadPath}
                      onChange={(e) =>
                        handleInputChange(e.target.value, index, "UploadPath")
                      }
                      placeholder="File Path"
                    />
                  </Form.Item>
                </Col>

                {/* Textbox for File Name */}
                <Col span={4}>
                  <Form.Item label="File Name">
                    <Input
                      value={item.UploadName}
                      onChange={(e) =>
                        handleInputChange(e.target.value, index, "UploadName")
                      }
                      placeholder="File Name"
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="Is Required">
                    <Select
                      value={item.UploadRequired}
                      onChange={(value) =>
                        handleInputChange(value, index, "UploadRequired")
                      }
                      placeholder="Select"
                    >
                      <Option value="" disabled>
                        Select
                      </Option>
                      {yesnoOptions.map(({ option, value }) => (
                        <Option key={option} value={value}>
                          {option}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="Is Multiple Upload">
                    <Select
                      value={item.UploadIsMultiple}
                      onChange={(value) =>
                        handleInputChange(value, index, "UploadIsMultiple")
                      }
                      placeholder="Select"
                    >
                      <Option value="" disabled>
                        Select
                      </Option>
                      {yesnoOptions.map(({ option, value }) => (
                        <Option key={option} value={value}>
                          {option}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="Retention Period">
                    <DatePicker
                      format={"YYYY-MM-DD"}
                      value={moment(item.UploadRetention)}
                      onChange={(e) =>
                        handleInputChange(
                          moment(e).format("YYYY-MM-DD"),
                          index,
                          "UploadRetention"
                        )
                      }
                      placeholder="Retention Period"
                    />
                  </Form.Item>
                </Col>

                {/* Remove Button */}
                <Col span={2}>
                  <Button
                    type="danger"
                    onClick={() => handleRemove(index)}
                    disabled={config.fileupload.length === 1}
                  >
                    -
                  </Button>
                </Col>
              </Row>
            ))}

            {/* Add Button */}
            <Form.Item>
              <Button type="primary" onClick={handleAdd}>
                +
              </Button>
            </Form.Item>
          </>
        )}
        {datatypeConfig == "sub_view" && (
          <Col span={5}>
            <Form.Item
              label="Select View"
              name="exprow"
              initialValue={config.exprow || ""}
            >
              <Select showSearch>
                <Option value="" disabled>
                  Select
                </Option>
                {viewlist.map(({ view_id, view_name }) => (
                  <Option key={view_id} value={view_id}>
                    {view_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}
        {datatypeConfig == "dropdownlist" && (
          <>
            <Col span={5}>
              <Form.Item
                label="dropdownlist"
                name="dropdownlist"
                initialValue={config.dropdownlist || ""}
              >
                <Select mode="multiple" showSearch>
                  <Option value="" disabled>
                    Select
                  </Option>
                  {dropdownlist.map(({ dropdown_id, dropdown_name }) => (
                    <Option key={dropdown_id} value={dropdown_id}>
                      {dropdown_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={3}>
              <Button
                className="delete_view_field"
                type="primary"
                disabled={!config.dropdownlist}
                onClick={() => filteroutput(config.dropdownlist)}
              >Config Output</Button>
            </Col>
            <Col span={3}>
              <Form.Item
                label="Show Dropdown Options"
                name="dropDownOptions"
                initialValue={config.dropDownOptions || ""}
              >
                <Select>
                  <Option value="" disabled>
                    Select
                  </Option>
                  {dropDownOptions.map(({ option, value }) => (
                    <Option key={option} value={value}>
                      {option}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </>
        )}
        {datatypeConfig == "hyperlink" && (
          <Col span={5}>
            <Form.Item
              label="Get Hyperlink Data"
              name="gethyperlinkdata"
              initialValue={config.gethyperlinkdata || ""}
            >
              <Select showSearch>
                <Option value="" disabled>
                  Select
                </Option>
                {hyperlinkdata.map(({ option, value }) => (
                  <Option key={option} value={value}>
                    {option}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}
        {config.gethyperlinkdata == "API" && (
          <Col span={3}>
            <Form.Item
              label="Select API"
              name="hyperlinkapi"
              initialValue={config.hyperlinkapi}
            >
              <Select showSearch>
                <Option value="" disabled>
                  Select
                </Option>
                {apidata.map(({ action_name }) => (
                  <Option key={action_name} value={action_name}>
                    {action_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}
        {config.gethyperlinkdata == "PD" && (
          <Col span={3}>
            <Form.Item
              label="Payload Path"
              name="HyperlinkPayload"
              type="textarea"
              initialValue={config.HyperlinkPayload}
            >
              <Input />
            </Form.Item>
          </Col>
        )}
        {config.gethyperlinkdata == "productlist" && (
          <>
            <Col span={5}>
              <Form.Item
                label="Save Product ID"
                name="productid"
                initialValue={config.productid || ""}
              >
                <Select showSearch>
                  <Option value="" disabled>
                    Select
                  </Option>
                  {fieldlist.map(({ BucketField, fieldname }) => (
                    <Option key={fieldname} value={BucketField}>
                      {fieldname}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                label="Save Product Amount"
                name="productlist"
                initialValue={config.productlist || ""}
              >
                <Select showSearch>
                  <Option value="" disabled>
                    Select
                  </Option>
                  {fieldlist.map(({ BucketField, fieldname }) => (
                    <Option key={fieldname} value={BucketField}>
                      {fieldname}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                label="Save Product Tenure"
                name="tenure"
                initialValue={config.tenure || ""}
              >
                <Select showSearch>
                  <Option value="" disabled>
                    Select
                  </Option>
                  {fieldlist.map(({ BucketField, fieldname }) => (
                    <Option key={fieldname} value={BucketField}>
                      {fieldname}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </>
        )}

        {/* Dynamic Fields for Data Type = "Date" */}
        {config.datatype === "date" &&
          fieldSets.map((fieldSet, index) => (
            <React.Fragment key={fieldSet.id}>
              <Col span={5}>
                <Form.Item
                  label="Display Format"
                  name="displaydateformat"
                  type="textarea"
                  placeholder="dd/mm/yyyy"
                  initialValue={config.displaydateformat}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item
                  label="Operator"
                  name={`operator-${fieldSet.id}`}
                  initialValue={config[`operator-${fieldSet.id}`] || ""}
                >
                  <Select>
                    <Option value="" disabled>
                      Select Operator
                    </Option>
                    <Option value="==">==</Option>
                    <Option value="exists">exists</Option>
                    <Option value=">">&#62;</Option>
                    <Option value="<">&#60;</Option>
                    <Option value="!=">!=</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item
                  label="Day"
                  name={`day-${fieldSet.id}`}
                  initialValue={
                    config[`day-${fieldSet.id}`] !== undefined ? config[`day-${fieldSet.id}`] : ""
                  }
                >
                  <Select
                    onChange={(value) => {
                      // Update the `config` state when a day is selected
                      valueChangeHandler(
                        { [`day-${fieldSet.id}`]: value },
                        { ...config, [`day-${fieldSet.id}`]: value }
                      );
                    }}
                  >
                    <Option value="" disabled>
                      Select Day
                    </Option>
                    {Array.from({ length: 37 }, (_, index) => index - 5).map((day) => (
                      <Option key={day} value={day}>
                        {day === 0 ? "0 Day" : `${day} Day${Math.abs(day) === 1 ? "" : "s"}`}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              {/* Color Picker Toggle Button */}
              <Col span={5}>
                <button
                  className="btn btn-primary"
                  onClick={() => toggleColorPicker(fieldSet.id)}
                  style={{ width: "60%", marginTop: "25px" }}
                >
                  {colorPickers[fieldSet.id] && colorPickers[fieldSet.id].display
                    ? "Close Picker"
                    : "Pick Color"}
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => removeFieldSet(fieldSet.id)}
                  style={{ marginTop: "25px", marginLeft: "10px" }}
                >
                  -
                </button>
              </Col>

              {/* Color Picker */}
              {colorPickers[fieldSet.id] && colorPickers[fieldSet.id].display && (
                <Col span={5}>
                  <Form.Item label="Color" name={`color-${fieldSet.id}`}>
                  <ChromePicker
                    color={
                      (colorPickers[fieldSet.id] && colorPickers[fieldSet.id].color) ||
                      config[`color-${fieldSet.id}`] ||
                      "#ffffff"
                    }
                    onChangeComplete={(color) => handleColorChange(color, fieldSet.id)}
                  />
                  </Form.Item>
                </Col>
              )}
            </React.Fragment>
          ))}

          {/* Add "+" Button only after selecting "Date" as Data Type */}
          {config.datatype === "date" && (
            <Col span={5}>
              <button
                className="btn btn-success"
                onClick={addFieldSet}
                style={{ width: "60%", marginTop: "25px" }}
              >
                + Add Field Set
              </button>
            </Col>
          )
        }
        <Col span={3}>
          <Form.Item
            label="Redirect"
            name="isRedirect"
            initialValue={config.isRedirect || ""}
          >
            <Select onChange={(value) => setRedirectConfig(value)}>
              <Option value="" disabled>
                Select
              </Option>
              {yesnoOptions.map(({ option, value }) => (
                <Option key={option} value={value}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {redirectConfig == "1" && (
          <>
            <Col span={5}>
              <Form.Item
                label={`use {"{{ fieldname }}"}`}
                name="RedirectData"
                type="textarea"
                initialValue={config.RedirectData}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                label="Add Redirect Condition"
                name="RedirectCondition"
                initialValue={config.RedirectCondition}
              >
                <Input />
              </Form.Item>
            </Col>
          </>
        )}
        <Col span={3}>
          <Form.Item
            label="EditinPopup"
            name="EditinPopup"
            initialValue={config.EditinPopup || ""}
          >
            <Select>
              <Option value="" disabled>
                Select
              </Option>
              {yesnoOptions.map(({ option, value }) => (
                <Option key={option} value={value}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {isArray == 1 && (
          <>
            <Col span={4}>
              <Form.Item
                label="Pick Value From Array"
                name="picklast"
                initialValue={config.picklast || ""}
              >
                <Select>
                  <Option value="" disabled>
                    Select
                  </Option>
                  {yesnoOptions.map(({ option, value }) => (
                    <Option key={option} value={value}>
                      {option}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {config.picklast == 1 && (
              <Col span={3}>
                <Form.Item
                  label="Array Index"
                  name="pickindex"
                  initialValue={config.pickindex || ""}
                >
                  <Input />
                </Form.Item>
              </Col>
            )}
          </>
        )}
        <Col span={5}>
          <Form.Item
            label="Field Length"
            name="FieldLength"
            initialValue={config.FieldLength || null}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item
            label="Use as Add Data Before"
            name="add_data_before"
            initialValue={config.add_data_before || ""}
          >
            <Select>
              <Option value="" disabled>
                Select
              </Option>
              {yesnoOptions.map(({ option, value }) => (
                <Option key={option} value={value}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      {dropdownResponse && (
        <Row gutter={[24, 16]}>
          <Col span={24}>
            {/* <Form.Item
                  name="filtereddropdownlist"
                  initialValue={config.filtereddropdownlist || ""}
                >
                  <Checkbox.Group
                    options={dropdownResponse}
                    //onChange={onChange}
                    defaultValue={config.filtereddropdownlist || ""}
                  />
                </Form.Item> */}
            <table className="table">
              <thead>
                <th>#</th>
                <th>Use For</th>
                {config.massupdate == 1 && <><th>Mass Condition</th>
                  <th>Validation to Stop Mass Processing</th></>}
              </thead>
              <tbody>
                {dropdownResponse &&
                  dropdownResponse.map((showdata) => {
                    const dropdowncondition = config.dropdowncondition || [];
                    const existingSelection = dropdowncondition.find(
                      (item) =>
                        item.filtereddropdownlist === showdata.item_value
                    );

                    return (
                      <tr key={showdata.item_id}>
                        <td>
                          <Checkbox
                            value={showdata.item_value}
                            checked={!!existingSelection}
                            onChange={(e) =>
                              handleCheckboxChange(e, showdata.item_value)
                            }
                          >
                            {showdata.item_name + " - " + showdata.item_value}
                          </Checkbox>
                        </td>
                        <td>
                          <Select
                            onChange={(value) =>
                              handleusedfor(
                                value,
                                showdata.item_value
                              )
                            }
                            value={
                              existingSelection
                                ? existingSelection.usedfor
                                : ""
                            }
                            placeholder="Select"
                          >
                            {(config.massupdate == 1 ? dropdownfilter : dropdownfilter.filter(item => item.value === "RD")).map(({ option, value }) => (
                              <Option key={option} value={value}>
                                {option}
                              </Option>
                            ))}
                          </Select>
                        </td>
                        {config.massupdate == 1 && <>
                          <td>
                            <Select
                              onChange={(value) =>
                                handleConditionChange(value, showdata.item_value)
                              }
                              value={
                                existingSelection
                                  ? existingSelection.filteredCondition
                                  : undefined
                              }
                              placeholder="Select"
                            >
                              {dropdown_Condition.map(({ option, value }) => (
                                <Option key={value} value={value}>
                                  {option}
                                </Option>
                              ))}
                            </Select>
                          </td>
                          <td>
                            <Select
                              onChange={(value) =>
                                handleMassValidationKey(
                                  value,
                                  showdata.item_value
                                )
                              }
                              value={
                                existingSelection
                                  ? existingSelection.massValidationKey
                                  : undefined
                              }
                              placeholder="Select"
                            >
                              {allRules.map(({ field }) => (
                                <Option key={field} value={field}>
                                  {field}
                                </Option>
                              ))}
                            </Select>
                            <Select
                              mode="multiple"
                              onChange={(value) =>
                                handleMassValidationvalue(
                                  value,
                                  showdata.item_value
                                )
                              }
                              value={
                                existingSelection
                                  ? existingSelection.massValidationvalue
                                  : undefined
                              }
                              placeholder="Select"
                            >
                              {allRules.length > 0 &&
                                allRules[0].values.map((field) => (
                                  <Option key={field} value={field}>
                                    {field}
                                  </Option>
                                ))}
                            </Select>
                          </td>
                        </>}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </Col>
        </Row>
      )}
      <Row gutter={[24, 16]}>
        <Col span={3}>
          <Form.Item
            label="Get From API"
            name="getfromapi"
            initialValue={config.getfromapi || "0"}
          >
            <Select>
              <Option value="" disabled>
                Select
              </Option>
              {yesnoOptions.map(({ option, value }) => (
                <Option key={option} value={value}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {config.getfromapi == 1 && (
          <Col span={5}>
            <Form.Item
              label="Select API"
              name="getdatafromapi"
              initialValue={config.getdatafromapi || ""}
            >
              <Select showSearch>
                <Option value="" disabled>
                  Select
                </Option>
                {apidata.map(({ action_name }) => (
                  <Option key={action_name} value={action_name}>
                    {action_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}
      </Row>
      <Row gutter={[24, 16]}>
        {view_type && view_type == "AV" && (
          <Col span={3}>
            <Form.Item
              label="Aggregate Column"
              name="aggregatecolumn"
              initialValue={config.aggregatecolumn || ""}
            >
              <Select showSearch>
                <Option value="" disabled>
                  Select
                </Option>
                {aggregateOptions.map(({ option, value }) => (
                  <Option key={option} value={value}>
                    {option}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}
        <Col span={3}>
          <Form.Item
            label="Mapping Sequence"
            name="globalMappingSequence"
            initialValue={config.globalMappingSequence || 9999}
          >
            <InputNumber min={1} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[24, 16]}>
        <Col span={6}>

          <Form.Item
            label="Color"
            name="color"
          >
            <button
              className="btn btn-primary"
              onClick={() =>
                setcolorbtn(!colorbtn)
              }
            >
              {colorbtn ? "Close" : "Pick Color"}
            </button>
            {colorbtn && <ChromePicker
              color={config.color || "#000000"}
              onChangeComplete={(color) =>
                handleInputcoloeChange(
                  "color",
                  color.hex
                )
              }
            />}
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item
            label="Apply Currency Format"
            name="currencyFormat"
            initialValue={config.currencyFormat || "0"}
          >
            <Select>
              <Option value="" disabled>
                Select
              </Option>
              {yesnoOptions.map(({ option, value }) => (
                <Option key={option} value={value}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item
            label="Bold Text"
            name="boldText"
            initialValue={config.boldText || "0"}
          >
            <Select>
              <Option value="">
                Select
              </Option>
              {yesnoOptions.map(({ option, value }) => (
                <Option key={option} value={value}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item
            label="Add Icon"
            name="addIcon"
            initialValue={config.addIcon || "0"}
          >
            <Select>
              <Option value="" disabled>
                Select
              </Option>
              {yesnoOptions.map(({ option, value }) => (
                <Option key={option} value={value}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {config.addIcon == "1" && <><Col span={3}>
          <Form.Item
            label="Icon Position"
            name="iconPosition"
            initialValue={config.iconPosition}
          >
            <Select>
              <Option value="" disabled>
                Select
              </Option>
              {iconPosition.map(({ option, value }) => (
                <Option key={option} value={value}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
          <Col span={3}>
            <Form.Item
              label="Select Icon"
              name="icontoshow"
              initialValue={config.icontoshow}
            >
              <Select>
                <Option value="" disabled>
                  Select
                </Option>
                {iconList.map(({ option, value }) => (
                  <Option key={option} value={value}>
                    {option}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col></>}
      </Row>
      <PEButton element_id="createview_delete"
        className="delete_view_field"
        type="primary"
        danger
        onClick={deleteConfig}
      >
        <DeleteFilled />
      </PEButton>
    </Form>
  );
};

function createNestedJson(objectsList) {
  const result = {};
  function getConfigString(obj, value) {
    if (
      (typeof value === "object" && Object.keys(value).length === 0) ||
      value == undefined
    ) {
      value = "[]";
    }
    const config = { ...obj };
    // delete config['BucketField'];

    let x = JSON.parse(value);
    return JSON.stringify([...x, config]);
  }
  objectsList.forEach((obj) => {
    const keys = obj.BucketField.split(".");
    let currentLevel = result;

    keys.forEach((key, index) => {
      // console.log(key)
      if (key.includes(":")) {
        const nestedKeys = key.split(":");
        nestedKeys.forEach((currentKey, nestedIndex) => {
          if (nestedIndex === nestedKeys.length - 1) {
            if (index != keys.length - 1) {
              if (!currentLevel[currentKey]) {
                currentLevel[currentKey] = {};
              }
            } else {
              currentLevel[currentKey] = getConfigString(
                obj,
                currentLevel[currentKey]
              );
            }
            currentLevel = currentLevel[currentKey];
          } else {
            currentLevel[currentKey] = currentLevel[currentKey] || [];
            currentLevel = currentLevel[currentKey];
          }
        });
      } else {
        if (!currentLevel[key]) {
          currentLevel[key] = {};
        }

        if (index === keys.length - 1) {
          currentLevel[key] = getConfigString(obj, currentLevel[key]);
        } else {
          currentLevel = currentLevel[key];
        }
      }
    });
  });

  return result;
}

function setLeafNodes(obj, value) {
  for (const key in obj) {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      setLeafNodes(obj[key], value);
    } else {
      obj[key] = value;
    }
  }
}

function getBucketFieldString(path) {
  return [...path].reverse().reduce((str, value, index) => {
    str += value;
    if (typeof value == "string" && index != path.length - 1) {
      str += ".";
    } else if (typeof value == "number" && index != path.length - 1) {
      str += ":";
    }
    return str;
  }, "");
}

function getObjectPath(bucketField) {
  const _path = bucketField.split(".");
  const path = _path.reduce((pathArr, key) => {
    if (key.includes(":")) {
      key.split(":").forEach((item, index) => {
        if (index == 0) pathArr.push(item);
        else pathArr.push(parseInt(item));
      });
    } else {
      pathArr.push(key);
    }
    return pathArr;
  }, []);
  return path;
}
